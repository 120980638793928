<template lang="pug">
    router-view
</template>

<script>
export default {
    name: 'BdiOrganizationShell',
    data() {
        return {
            
        }
    },
    methods: {
        
    },
}
</script>
